<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="flex justify-between items-center mt-5">
        <h1 class="text-xl mt-3 mb-5">{{ $t("news_list") }}</h1>
        <t-button
          class="text-sm rounded-md mr-2"
          variant="primary"
          @click="newsModal = true"
        >
          {{ $t("news_add") }}
        </t-button>
      </div>
      <div class="overflow-x-auto mb-10">
        <t-table :headers="newsHeader" :data="news">
          <template slot="row" slot-scope="props">
            <tr>
              <td :class="props.tdClass">{{ props.row.date | date }}</td>
              <td :class="props.tdClass">{{ props.row.title }}</td>
              <td :class="props.tdClass">{{ props.row.body }}</td>
              <td :class="props.tdClass">
                <div class="flex items-center">
                  <t-button
                    class="text-sm rounded-md mx-1"
                    @click="newsDetails(props.row)"
                    :disabled="loading"
                  >
                    {{ $t("news_edit") }}
                  </t-button>
                  <t-button
                    class="text-sm rounded-md mx-1"
                    @click="remove(props.row.id)"
                    :disabled="loading"
                  >
                    {{ $t("news_delete") }}
                  </t-button>
                </div>
              </td>
            </tr>
          </template>
        </t-table>
      </div>
      <t-modal v-model="newsModal" @closed="clearError">
        <div class="flex flex-nowrap mb-3">
          <div class="px-2">
            <label>{{ $t("news_date") }}</label>
            <t-datepicker
              v-model="form.date"
              :variant="form.error.date ? 'danger' : ''"
            />
          </div>
        </div>
        <div class="mb-3 px-2">
          <label>{{ $t("news_title") }}</label>
          <t-input
            class="w-full"
            v-model="form.title"
            :variant="form.error.title ? 'danger' : ''"
          />
        </div>
        <div class="mb-3 px-2">
          <label>{{ $t("news_body") }}</label>
          <t-textarea
            class="w-full"
            rows="6"
            v-model="form.body"
            :variant="form.error.body ? 'danger' : ''"
          />
        </div>
        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button
              class="text-sm"
              variant="primary"
              :disabled="loading"
              @click="onSave()"
            >
              {{ $t("edit_save") }}
            </t-button>
          </div>
        </template>
      </t-modal>
      <t-dialog icon="question" type="confirm"></t-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import { get, omit } from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Layout },
  data: () => ({
    form: {
      id: null,
      date: moment().format("YYYY-MM-DD"),
      title: null,
      body: null,
      error: {
        date: false,
        title: false,
        body: false,
      },
    },
    loading: false,
    newsModal: false
  }),
  mounted() {
    this.getNewsList();
  },
  methods: {
    ...mapActions({
      getNews: actions.NEWS_FETCH_ACTION,
      createNews: actions.NEWS_CREATE_ACTION,
      editNews: actions.NEWS_UPDATE_ACTION,
      removeNews: actions.NEWS_REMOVE_ACTION,
    }),
    async getNewsList() {
      this.loading = true;

      try {
        await Promise.all([
          this.getNews({ locationId: this.locationId }),
        ]);

        console.log('DEBUG: news', this.news);
      } catch (error) {
        console.error([actions.NEWS_FETCH_ACTION], error);
      }
      this.loading = false;
    },
    async newsDetails(event) {
      this.form.id = get(event, 'id'),
      this.form.date = moment(get(event, 'date')).format("YYYY-MM-DD"),
      this.form.title = get(event, 'title'),
      this.form.body = get(event, 'body')

      this.newsModal = true;
    },
    async onSave () {
      this.form.error.date = !this.form.date;
      this.form.error.title = !this.form.title;
      this.form.error.body = !this.form.body;

      if (!Object.keys(this.form.error).every((k) => !this.form.error[k])) {
        return;
      }

      const { isOk } = await this.$dialog.confirm(
        "Save news",
        `Are you sure?`,
        "info"
      );

      if (!isOk) {
        return;
      }

      get(this.form, 'id') ? this.edit() : this.add();
    },
    async add() {
      try {
        await this.createNews({
          ...omit(this.form, ["error"]),
          horeca_location_id: this.locationId,
          date: this.form.date,
          title: this.form.title,
          body: this.form.body
        });
        this.newsModal = false;
      } catch (error) {
        console.error(actions.NEWS_CREATE_ACTION, error);
      }
    },
    async edit() {
      try {
        this.loading = true;
        const payload = {
          id: this.form.id,
          horeca_location_id: this.locationId,
          date: this.form.date,
          title: this.form.title,
          body: this.form.body
        };

        if (this.form.id) {
          await this.editNews({ payload });
        }
        this.newsModal = false;
      } catch (error) {
        console.error(actions.NEWS_UPDATE_ACTION, error);
      }

      this.loading = false;
    },
    async remove(id) {
      try {
        const { isOk } = await this.$dialog.confirm(
          "Remove news",
          `Are you sure?`,
          "info"
        );

        if (!isOk) {
          return;
        }

        this.loading = true;
        await this.removeNews({ id });

      } catch (error) {
        console.error(actions.NEWS_REMOVE_ACTION, error);
      }

      this.loading = false;
    },
    clearError() {
      this.form = {
        id: null,
        date: moment().format("YYYY-MM-DD"),
        title: null,
        body: null,
        error: {
          date: false,
          title: false,
          body: false,
        },
      };
    },
  },
  computed: {
    ...mapGetters({
      news: getters.NEWS_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    newsHeader() {
      return [
        {
          value: "news_date",
          text: this.$i18n.t("news_date"),
        },
        {
          value: "news_title",
          text: this.$i18n.t("news_title"),
        },
        {
          value: "news_body",
          text: this.$i18n.t("news_body"),
        },
        {},
      ];
    }
  },
  filters: {
    date(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  }
};
</script>

<style lang="scss" scoped>
table {
  border: 1px solid #ddd;
  width: 100%;

  &::v-deep th {
    text-align: left !important;
    background-color: #ddd;
    border-right: 1px solid white;
    padding: 5px 5px 5px 10px;
    min-width: 100px;
  }

  &::v-deep tbody > tr {
    &:hover, &:nth-child(odd) {
      background-color: #f9f9f9;
    }

    td {
      vertical-align: top;
      font-size: 14px;
      min-width: 200px;
      padding: 5px 5px 5px 10px;
    }
  }
}
</style>
